var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('n-table-view',{ref:"table",staticClass:"table6",attrs:{"name":"Список","genRequestBuilder":_vm.genRequestBuilder,"height":_vm.height,"headers":[
      {text:'ID', value:'id', type:'integer'},
      {text:'Дата', value:'created', type:'dateTime'},
      {text:'Тип', value:'operationGroupType', type:'operationGroupType'},
      {text:'Операции', sortable:false, type:'operationGroupOperations'},
      {text:'Итого', sortable:false, type:'operationGroupTotal'},
      {text:'Аналитика', sortable:false, type:'operationGroupAnalytics'},
    ]},scopedSlots:_vm._u([{key:"barActions",fn:function(){return [_vm._t("barActions")]},proxy:true},{key:"item",fn:function({item, headers}){return [_c('tr',{attrs:{"data-id":item.id},on:{"click":function($event){_vm.operationGroup=item}}},_vm._l((headers),function(v){return _c('td',{key:v.text},[_c('field-renders',{class:_vm.$tools.isEmpty(v.class) ? null : v.class,attrs:{"item":item,"header":v}})],1)}),0)]}}],null,true)}),_c('finance-operation-group',{attrs:{"operation-group":_vm.operationGroup},on:{"update:operationGroup":function($event){_vm.operationGroup=$event},"update:operation-group":function($event){_vm.operationGroup=$event},"fixed":function($event){return _vm.refresh()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }