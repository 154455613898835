<template>
  <div>
    <n-table-view
        ref="table"
        class="table6"
        name="Список"
        :genRequestBuilder="genRequestBuilder"
        :height="height"
        :headers="[
          {text:'ID', value:'id', type:'integer'},
          {text:'Дата', value:'operationGroup.created', type:'dateTime'},
          {text:'Тип операции', sortable:false, value:'operationType.name', type:'operation_operationType_name'},
          {text:'Комментарий', sortable:false, value:'operationGroup.comment', type:'comment'},
          {text:'Филиал(нет аналитики)', sortable:false},
          {text:'Касса', sortable:false, analyticName:'Касса'},
          {text:'Расчетный счет', sortable:false, analyticName:'Расчетный счет'},
          {text:'Статьи ДДС', sortable:false, analyticName:'Статьи ДДС'},
          {text:'Кассир', sortable:false, analyticName:'Создал', urlMask:'/panel/user/admin/#id#/'},
          {text:'Клиент', sortable:false, analyticName:'Клиент', urlMask:'/panel/user/client/#id#/'},
          {text:'ID клиента', sortable:false, analyticName:'Клиент', isRawValue:true},
          {text:'Итого', sortable:false},
        ]"
    >
      <template v-slot:barActions>
        <slot name="barActions"/>
      </template>
      <template v-slot:item="{item,headers}">
        <tr @click="operationGroup=item" :data-id="item.id">
          <td v-for="v in headers" :key="v.text">
            <field-renders v-if="typeof v.type!=='undefined'" :item="item" :header="v" :class="$tools.isEmpty(v.class) ? null : v.class"/>
            <operation-analytic-finder v-else :name="v.analyticName" :urlMask="v.urlMask" :isRawValue="v.isRawValue" :operation="item" />
          </td>
        </tr>
      </template>
    </n-table-view>

  </div>
</template>

<script>
import NTableView from "@/componentsV2/base/NTableView.vue";
import {
  Entity_AnalyticType,
  Entity_AnalyticValueFrom, Entity_AnalyticValueGroup, Entity_AnalyticValueTo,
  Entity_Operation, Entity_OperationGroup, Entity_OperationType,
} from "../../../EntityStoreCacheService";
import FieldRenders from "@/componentsV2/base/FieldRenders.vue";
import OperationAnalyticFinder from "@/componentsV2/custom/OperationAnalyticFinder.vue";

export default {
  components: {
    OperationAnalyticFinder,
    FieldRenders,
    NTableView,
  },
  props:['height','disabled','genRequestBuilderParams'],
  data: () => ({
    operationGroup:null
  }),
  methods:{
    load(){
      this.$refs.table.load();
    },

    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_Operation()
          .selects(Entity_Operation.id)
          .selects(Entity_Operation.count)
          .selects(Entity_Operation.discount)
          .selects(Entity_Operation.amount)

          .selects([Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.id].join('.'))
          .selects([Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.valueName].join('.'))
          .selects([Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.value].join('.'))
          .selects([Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.analyticTypeFrom, Entity_AnalyticType.name].join('.'))

          .selects([Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.id].join('.'))
          .selects([Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.valueName].join('.'))
          .selects([Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.value].join('.'))
          .selects([Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.analyticTypeTo, Entity_AnalyticType.name].join('.'))

          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.id].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.created].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.comment].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.fixed, Entity_OperationGroup.id].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.fixed, Entity_OperationGroup.created].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.fixedBy, Entity_OperationGroup.id].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.fixedBy, Entity_OperationGroup.created].join('.'))

          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.id].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.valueName].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.value].join('.'))
          .selects([Entity_Operation.operationGroup, Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.analyticType, Entity_AnalyticType.name].join('.'))

          .selects([Entity_Operation.operationType, Entity_OperationType.name].join('.'))

      rb.page(page).onPage(onPage)

      rb = this.genRequestBuilderParams(rb);
      sortFields.forEach(v=>{
        rb.order(v.field, v.isAsc)
      })

      return rb;
    },
  }
}
</script>