<template>
  <tooltip bottom>
    <template v-slot:default>
      <chip :class="chipClass">{{text}}</chip>
    </template>
    <template v-slot:content>
      {{info}}
    </template>
  </tooltip>
</template>
<script>
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import Chip from "@/componentsV2/base/Chip.vue";

export default {
  components: {Chip, Tooltip},
  props: [
    'text',
    'info',
    'chipClass'
  ]
}
</script>