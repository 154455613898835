<template>
  <v-list class="pa-0" nav>
    <v-list-item
        v-for="item in FinanceApi.payTypes" :key="item.id"
        class="mb-0 pa-0"
        @click="$tools.selectToggle(localSelect, item)"
    >
      <v-list-item-content class="py-0 px-2">
        <v-row no-gutters class="align-center">
          <v-col cols="auto">
            <v-checkbox
                :input-value="$tools.isSelect(localSelect, item)"
                color="primary" class="mb-2" dense hide-details />
          </v-col>
          <v-col cols="auto" class="mr-2"><v-icon :color="item.color" size="18">{{item.icon}}</v-icon></v-col>
          <v-col class="body-2">{{item.name}}</v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import FinanceApi from "@/views/Finance/FinanceApi";

export default {
  props:['select'],
  data: () => ({
    localSelect:[],
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    }
  },
  computed: {
    FinanceApi() {
      return FinanceApi
    }
  },
}
</script>