<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="tabSelected" background-color="transparent">
        <v-tab>Операции</v-tab>
        <v-tab>Кассовое оборудование</v-tab>
        <v-tab>Стандарт</v-tab>
      </v-tabs>
    </portal>
    <finance-operations v-if="tabSelected===0"/>
    <devices v-if="tabSelected===1"/>
    <finance-standart v-if="tabSelected===2"/>
  </div>
</template>

<script>
import FinanceOperations from "@/views/Finance/FinanceOperations.vue";
import Devices from "@/views/Device/Devices.vue";
import FinanceStandart from "@/views/Finance/FinanceStandart.vue";

export default {
  components: {FinanceStandart, Devices, FinanceOperations},
  data:()=>({
    tabSelected:0,
  })
}
</script>


