import {Entity_OperationGroupType, Entity_OperationType} from "../../../EntityStoreCacheService";

export default class FinanceApi{
    static payTypes = [
        {id: 'pay', name:"Оплата","color":"green","icon":"mdi-arrow-up"},
        {id: 'payCancel', name:"Возврат оплаты","color":"red","icon":"mdi-arrow-u-down-right"},
        {id: 'accountIn', name:"Пополнение аванса","color":"green","icon":"mdi-arrow-up-circle"},
        {id: 'accountSend', name:"Перевод аванса","color":"blue","icon":"mdi-arrow-up-circle"},
        {id: 'accountOut', name:"Возврат с аванса","color":"red","icon":"mdi-arrow-down-circle"},
        {id: 'internalIn', name:"Приход","color":"green","icon":"mdi-arrow-right-circle"},
        {id: 'internalOut', name:"Расход","color":"red","icon":"mdi-arrow-left-circle"}
    ];
    static sourceTypes = [
        {id: 'cash', name: 'Наличные', icon: 'mdi-cash'},
        {id: 'card', name: 'Банковская карта', icon: 'mdi-credit-card-outline'},
        {id: 'bankAccount', name: 'Расчетный счет', icon: 'mdi-bank'},
        {id: 'account', name: 'Аванс', icon: 'mdi-account-cash-outline'},
        {id: 'oms', name: 'ОМС', icon: 'mdi-plus-box'},
        {id: 'dms', name: 'ДМС', icon: 'mdi-plus-box-multiple'},
        {id: 'contract', name: 'Контракт', icon: 'mdi-domain'},
    ];
    static operationGroupTypes = [];
    static cats = [];

    static loadOperationGroupTypes(callback){
        if(FinanceApi.operationGroupTypes.length!==0) {
            callback(FinanceApi.operationGroupTypes, FinanceApi.sourceTypes, FinanceApi.cats)
            return null;
        }

        new Entity_OperationGroupType()
            .selects(Entity_OperationGroupType.id)
            .selects(Entity_OperationGroupType.name)
            .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.id].join('.'))
            .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.name].join('.'))
            .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.sourceType].join('.'))
            .order(Entity_OperationGroupType.name, true)
            .addStateCallback((r)=>{
                if(r.success) {
                    let operationGroupTypes = r.items;
                    let cats = [];

                    let groupAddItem = (groupName, groupIcon, groupColor, searchName)=>{
                        if(cats.filter(v=>v.id===groupName).length===0)
                            cats.push({id:groupName, icon:groupIcon, color:groupColor, items:[]});

                        let found = false;
                        cats.filter(v=>v.id===groupName).forEach(v=>{
                            operationGroupTypes.filter(v1=>v1.name===searchName).forEach(v1=>{
                                v.items.push(v1);
                                found = true;
                            });
                        })
                        if(!found)
                            console.log('not found: '+searchName);
                    }

                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Поступление наличных');
                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Поступление карта');
                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Подтверждение карта');
                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Поступление банк');
                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Подтверждение банк');
                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Поступление банк от организации');
                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Поступление банк по ОМС');
                    groupAddItem('Поступление', 'mdi-arrow-up', 'green', 'Поступление банк по ДМС');

                    groupAddItem('В процессе', 'mdi-clock-outline', 'blue', 'Неподтвержденный карта');
                    groupAddItem('В процессе', 'mdi-clock-outline', 'blue', 'Неподтвержденный счет');

                    groupAddItem('Возврат', 'mdi-arrow-down', 'red', 'Возврат наличных');
                    groupAddItem('Возврат', 'mdi-arrow-down', 'red', 'Возврат карта');
                    groupAddItem('Возврат', 'mdi-arrow-down', 'red', 'Возврат банк');
                    groupAddItem('Возврат', 'mdi-arrow-down', 'red', 'Возврат организации');
                    groupAddItem('Возврат', 'mdi-arrow-down', 'red', 'Возврат ОМС');
                    groupAddItem('Возврат', 'mdi-arrow-down', 'red', 'Возврат ДМС');
                    groupAddItem('Возврат', 'mdi-arrow-down', 'red', 'Возврат на аванс');

                    groupAddItem('Аванс', 'mdi-swap-vertical-circle', 'green', 'Зачет аванса');
                    groupAddItem('Аванс', 'mdi-swap-vertical-circle', 'green', 'Перевод аванса');

                    groupAddItem('Приход / Расход', 'mdi-swap-horizontal', 'grey darken-1', 'Прочее поступление наличных');
                    groupAddItem('Приход / Расход', 'mdi-swap-horizontal', 'grey darken-1', 'Прочая выдача наличных');

                    let arrAdd = [];
                    operationGroupTypes.forEach(v1=>{
                        let found = false;
                        cats.forEach(v2=>{
                            if(v2.items.filter(v21=>v21.name===v1.name).length>0)
                                found = true;
                        })
                        if(!found)
                            arrAdd.push(v1);
                    })
                    if(arrAdd.length>0){
                        arrAdd = arrAdd.sort((a, b)=>a.name.localeCompare(b.name))
                        cats.push({id:'Дополнительно', icon:'mdi-dots-horizontal', color:'grey darken-1', items:arrAdd})
                    }

                    FinanceApi.operationGroupTypes = operationGroupTypes;
                    FinanceApi.cats = cats;
                    
                    callback(operationGroupTypes, cats);
                }
            })
            .request();
    }

    static getCatFromOperationGroupType(operationGroupTypeId){
        let res = null;
        FinanceApi.cats.forEach(v=>{
            v.items.forEach(v1=>{
                if(v1.id===operationGroupTypeId)
                    res = v;
            })
        })
        return res;
    }

    //https://www.lexilogos.com/keyboard/russian_conversion.htm
    static KLIENT = 'Клиент';
    static SOZDAL = 'Создал';

    static analyticValueLinkGenerate(v){
        if(v.name===this.KLIENT) return '/panel/user/client/'+v.value+'/';
        if(v.name===this.SOZDAL) return '/panel/user/client/'+v.value+'/';
        return null;
    }
    
}