var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
        _vm.operationGroup.reverseBy===null &&// еще не возвращена
        _vm.operationGroup.operationGroupType.reverseBys.length>0 && // может быть возвращена
        _vm.operationGroup.fixedBy===null // еще не сторнирована
      )?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","depressed":"","small":""},on:{"click":function($event){_vm.reverseByOpen=true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-arrow-u-down-right")]),_vm._v("Возврат ")],1):_vm._e(),(
        _vm.operationGroup.fixedBy===null // еще не сторнирована
      )?_c('btn-small-accept',{attrs:{"color":"error","btnClass":"mr-2","name":"Сторнировать","icon":"mdi-arrow-u-down-right","text":"Операция будет полностью сторнирована. Вы уверены?","loading":_vm.fixedByState.isLoading},on:{"accept":function($event){return _vm.fixedByAction();}}}):_vm._e(),_c('dialog-view',{attrs:{"open":_vm.reverseByOpen,"title":"Выберите способ возврата"},on:{"cancel":function($event){_vm.reverseByOpen=false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('radios',{attrs:{"name":"Хоз.операция","items":_vm.operationGroup.operationGroupType.reverseBys},on:{"update:select":(v)=>_vm.reverseByOpen=v}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$store.commit('alertWarning', 'В данный момент операция не поддерживается')}}},[_vm._v("ОК")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }