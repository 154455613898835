<template>
  <v-row no-gutters align="center">
    <v-col>
      <div>{{name}}</div>
      <div v-if="!panelOpen && localSelect.length>0" class="font-weight-medium">
        <v-sheet v-for="(v,k) in localSelect" :key="k" color="grey lighten-4" rounded class="px-2 py-1 d-inline-block mr-1 mt-1">
          <v-icon v-if="!$tools.isEmpty(v.icon)" small class="mr-2 d-inline-block">{{v.icon}}</v-icon>{{v.name}}
        </v-sheet>
      </div>
    </v-col>
    <v-col cols="auto" v-if="localSelect.length>0">
      <v-icon size="18" @click.stop="localSelect=[]" class="mr-2">mdi-delete-outline</v-icon>
    </v-col>
  </v-row>
</template>
<script>

export default {
  props:['panelOpen', 'name', 'select'],
  data:()=>({
    localSelect: []
  }),
  mounted() {
    this.localSelect = this.select;
  },
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    }
  },
}
</script>