<template>
  <group :name="name">
    <v-list class="pa-0" nav color="transparent">
      <v-list-item
          v-for="item in items" :key="item.id"
          @click="localSelect=item;$emit('update:select', localSelect)"
          class="mb-0"
          :disabled="typeof disabled==='undefined' ? false : disabled"
      >
        <v-radio-group dense :value="localSelect===null ? false : localSelect.id===item.id" class="pa-0 ma-0 position-absolute" style="top:8px">
          <v-radio :value="true" :disabled="typeof disabled==='undefined' ? false : disabled"/>
        </v-radio-group>
        <div class="ml-8">
          <v-icon v-if="!$tools.isEmpty(item.icon)" size="18" :color="item.color" class="mr-2">{{ item.icon }}</v-icon>
          {{ item.name }}
        </div>
      </v-list-item>
    </v-list>
  </group>
</template>

<script>
import Group from "@/componentsV2/base/Group.vue";

export default {
  components: {Group},
  props:['items','name','select','disabled'],
  data: () => ({
    localSelect:null,
  }),
  mounted() {
    this.localSelect = this.items[0];
    this.$emit('update:select', this.localSelect)
  },
  watch:{
    select(){
      this.localSelect = this.select;
    }
  }
}
</script>