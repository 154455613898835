var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('n-table-view',{ref:"table",staticClass:"table6",attrs:{"name":"Список","genRequestBuilder":_vm.genRequestBuilder,"height":_vm.height,"headers":[
        {text:'ID', value:'id', type:'integer'},
        {text:'Дата', value:'operationGroup.created', type:'dateTime'},
        {text:'Тип операции', sortable:false, value:'operationType.name', type:'operation_operationType_name'},
        {text:'Комментарий', sortable:false, value:'operationGroup.comment', type:'comment'},
        {text:'Филиал(нет аналитики)', sortable:false},
        {text:'Касса', sortable:false, analyticName:'Касса'},
        {text:'Расчетный счет', sortable:false, analyticName:'Расчетный счет'},
        {text:'Статьи ДДС', sortable:false, analyticName:'Статьи ДДС'},
        {text:'Кассир', sortable:false, analyticName:'Создал', urlMask:'/panel/user/admin/#id#/'},
        {text:'Клиент', sortable:false, analyticName:'Клиент', urlMask:'/panel/user/client/#id#/'},
        {text:'ID клиента', sortable:false, analyticName:'Клиент', isRawValue:true},
        {text:'Итого', sortable:false},
      ]},scopedSlots:_vm._u([{key:"barActions",fn:function(){return [_vm._t("barActions")]},proxy:true},{key:"item",fn:function({item,headers}){return [_c('tr',{attrs:{"data-id":item.id},on:{"click":function($event){_vm.operationGroup=item}}},_vm._l((headers),function(v){return _c('td',{key:v.text},[(typeof v.type!=='undefined')?_c('field-renders',{class:_vm.$tools.isEmpty(v.class) ? null : v.class,attrs:{"item":item,"header":v}}):_c('operation-analytic-finder',{attrs:{"name":v.analyticName,"urlMask":v.urlMask,"isRawValue":v.isRawValue,"operation":item}})],1)}),0)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }