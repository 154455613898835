<template>
  <autocomplete-single name="Организация" :items="arr" :select.sync="localSelect" @load="load" :loading="api.loading">
    <template v-slot:itemIcon="{item}">
      <v-sheet rounded color="grey lighten-4" class="caption px-1 mr-2">{{item.sid}}</v-sheet>
    </template>
  </autocomplete-single>
</template>

<script>
import GraphqlApi from "@/plugins/graphqlApi";
import Session from "@/plugins/session";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";

export default {
  components: {AutocompleteSingle},
  props: [
    'select',
  ],
  data: () => ({
    api: new GraphqlApi(),
    arr: [],
    localSelect: null
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    }
  },
  mounted() {
    this.localSelect = this.select;
  },
  methods: {
    load() {
      this.api.request(
          "/api/finance/graphql/",
          `
        query Query {
          accountPlans(
            onPage: 10000
            orderQL: "name asc"
          ) {
            count
            items{
              id
              sid
              name
            }
          }
        }
      `,
          {authToken: Session.token()},
          (d) => {
            this.arr = d.accountPlans.items;
            this.arr.forEach(v => {
              v._selected_name = v.sid + " - " + v.name;
            })
          },
          () => {
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
          }, true
      );
    }
  }
}
</script>